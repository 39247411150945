<template>
  <el-container class="container" ref="container" 
    style1="width:100%;height:100%;margin:0 auto;" :style="'max-width:'+divwidth+'px;'">
    <el-header style="height:190px;">
    <el-card style="margin-top:16px;" shadow1="never">
        <el-form :model="orders" :size="size">
            <el-form-item label="服务地址" label-width="90px" >
              <el-select v-model="orders.m_syzbh" clearable 
              @change="changeSelect" placeholder="选择送气服务地址" style="width:100%;">
                <el-option
                  v-for="item in Prmitlist"
                  :key="item.recid"
                  :label="item.ContactAddr"
                  :value="item.m_syzbh">
                  <span style="float: left">{{ item.ContactAddr}}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.Contact }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" label-width="90px" style="flex-grow:1;margin-top:8px;">
              <el-input  autocomplete="off" v-model="orders.lxmobile" maxlength="50" clearable 
              @keyup.enter.native="searchEnterFun">
              </el-input>
                
            </el-form-item>
            <div style="justify-content:space-between;display: flex;margin-top:8px;">
            <el-form-item label="服务备注" label-width="90px" style="flex-grow:1">
              <el-input  autocomplete="off" v-model="orders.cmemo" maxlength="11" clearable ></el-input>
            </el-form-item>
              <el-button type="success"  :size="size" plain icon="el-icon-tickets" style="margin-left:20px;" @click="showOrderManual=true">预约说明</el-button>
            </div>
        </el-form>
    </el-card>
    
  </el-header>  
  <el-main style="justify-content: flex-start;display: flex;flex-direction:column;overflow-y: auto;">
    <div v-for="item in Gaslist" :key="item.m_mpNo" >
      <el-card style="margin-bottom:15px;background: #f9ffff;" >
        <div style="display: flex;width:100%;min-width:250px;align-items:center;">
          <el-image  style="width:130px;height: 75px;"
            :src="item.imageurl"
            fit="scale-down"></el-image>
        <div style="display: flex;flex-direction:column;justify-content:space-between;
            width:100%;min-width:200px;">
            <div style="font-size: 14px;">气种：{{item.yylx}}</div>
            <div style="display: flex;align-items:center;margin-top:5px;font-size: 14px;">
              <div>价格：</div><div style="color:#f00">￥{{item.m_price}}</div>
            </div>
            <div style="display: flex;width:100%;align-items:center;justify-content:space-between;">
                <div style="font-size: 14px;">数量：</div>
              <el-input-number v-model="item.sl" :min="0" :max="6" size="medium" style="width:140px;"></el-input-number>
            </div>
        </div>
        </div>
      </el-card>
      
    </div>
  
  </el-main>
      <div style="display:flex;justify-content:space-between;width:100%;">
      <div style="width:30%;line-height:70px;background:#F2F6FC;color: #d40f17;font-size: 24px;text-align:center;">{{(iSum)?"￥ "+iSum:""}}</div>
      <div style="width:30%;line-height:70px;background:#909399;color: white;font-size: 18px;text-align:center;cursor:pointer;"
      @click="makeOrder(1)">报修</div>
      <div style="width:40%;line-height:70px;background:#F56C6C;color: white;font-size: 18px;text-align:center;cursor:pointer;" 
      @click="makeOrder(2)">确定预约</div>
      </div>
    <el-drawer
      :visible.sync="showOrderManual" :size="isPc?'25%':'60%'"
      direction="rtl" append-to-body :wrapperClosable="true"
      custom-class="drawer-prmit"
      ref="drawerPrmit"
      >
      <div class="demo-drawer__content">
      <div style="height:40px;margin-left:20px;color:#666;">操作说明</div>
      <div>
        <div style="margin:0px 0px;padding:20px 25px;background-color:#FFFFFF;display: flex;
        flex-direction:column;justify-content:space-between;min-width:100px;">
          <span style="color:#999;margin-bottom:10px;">
            1、请先绑定燃气使用证，然后选择送气地址；（如果没有使用证，请到就近瀛海站点实名办理）
          </span>
          <span style="color:#999;margin-bottom:10px;">
            2、输入本次预约送气的联系电话（默认是使用证上的联系方式），如有额外说明请输到备注栏。
          </span>
          <span style="color:#999;margin-bottom:10px;">
            3、点击所需燃气气种上的数量计数器，自动累计总金额，每天累积数量不能超6瓶。
          </span>
          <span style="color:#999;margin-bottom:10px;">
            4、通过菜单【预约订单】查询未审核列表的订单明细，误操作可以在10分钟内自助作废。
          </span>
          <span style="color:#999;margin-bottom:10px;">
            5、如有疑问请拨打瀛海订气热线59481717咨询，或直接电话预约订气。
          </span>
        </div>

      </div>
      <div class="demo-drawer__footer" style="margin:40px">
              <el-button @click="showOrderManual=false" :size="size" type="success" style="float: right;">我知道了</el-button>
      </div>
      
    </div>
    </el-drawer>  
  </el-container>
</template>

<script>
/* eslint-disable */
import QRCode from "qrcode";
import sfutil from '../../Api/identity.js';
export default {
  data() {
    return { 
      size: 'medium',//medium,small,mini,
      isPc:false,
      divwidth:600,
      showOrderManual:false,
      orders:{
        lxmobile:"",
        cmemo:"",
        m_syzbh:'',
      },
      Prmitlist:[],
      Gaslist:[],
      LoginInfo:[],
    }
  },
  watch: {
    getPrmitlistRefr() {
      console.log("使用证有变动。。。。");
      this.getPermitList()
      setTimeout(() => {
        this.changeSelect();
      }, 2500);
    },
  },
  computed: {
    iSum() {
      let isum=0
      this.Gaslist.forEach((item)=>{
        isum+=item.m_price*item.sl;
      })
      return isum;
    },
    getPrmitlistRefr(){
      return this.$store.getters.GetRefrshItem("PrmitlistRefr")
    },
  },
  methods: {
    searchEnterFun(e){
      var keyCode = window.event? e.keyCode:e.which;
      console.log(this.orders.lxmobile,keyCode)
    },
    changeSelect(){
      let syzbh=this.orders.m_syzbh
      let index=this.Prmitlist.findIndex((e)=>{
        return e.m_syzbh==syzbh
      })
      
      if (syzbh=="" || index==-1) {
        this.orders.m_syzbh="";
        this.orders.lxmobile="";
        //this.orders.cmemo="";
        return
      }
      let phone=this.Prmitlist[index].Mobile;
      let mobile=this.LoginInfo.Mobile;
      this.orders.lxmobile=(mobile==""?phone:mobile);
    },
    makeOrder(e){
      
      let m_syzbh=this.orders.m_syzbh;
      if (m_syzbh=='' || this.Prmitlist.findIndex(c=>c.m_syzbh==m_syzbh)==-1){
        this.$message.error("请选择有效的服务地址");
        return
      }
      this.orders.cmemo=this.$api.myFilter(this.orders.cmemo.trim());
      let lxmobile=this.$api.myFilter(this.orders.lxmobile.trim());
      this.orders.lxmobile=lxmobile
      if (!this.$api.checkMobile(lxmobile)) {
        this.$message.error("手机号有误，8、11位的数字");
        return;
      }
      //this.orders.lxmobile
      //let phoneReg = /(1[0-9]{10})|(5[0-9]{7})/g;
      //let phone=cmemo.match(phoneReg);
      //this.orders.lxmobile=""
      //if (phone) {
      //  this.orders.lxmobile=phone[0];
      // this.orders.cmemo=cmemo.replace(phone[0],'');
      //}
      let Gaslist=[];
      if (e=='2'){
        Gaslist=this.Gaslist.map((el)=>{
          return {"m_mpNo":el.m_mpNo,"sl":""+el.sl}
        })
        if (this.iSum==0){
          this.$message.error("请选择所需气种的数量");
          return
        }
      }
      
      this.$confirm("真的要预约"+(e=="1"?"报修":"订气"+this.iSum+"元")+"吗?", "确认操作", {
          confirmButtonText: "确定",cancelButtonText: "取消",
          type: "info",iconClass: "el-icon-question",
        })
        .then(() => {
          console.log('aaa',e,JSON.stringify(this.orders),JSON.stringify(Gaslist))
          let data = {
            procext:"yhjj",
            lx: (e=="1"?"saverepairorder":"savegasorder"),
            sqlone:JSON.stringify(this.orders),
            sqltwo:(e=="1"?"":JSON.stringify(Gaslist)),
          };
          this.$api.post("",data,
            (res) => {
              this.$message.success(res["message"]);
              if (res["status"] == "1") {
                this.$store.commit("SetRefrshItem","OrderlistRefr");         
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    initDivSize() {
      let u = navigator.userAgent;
      let is_WX=u.indexOf("micromessenger") > -1;
      let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      let imgcol = this.$refs.container.offsetWidth;
      if (is_WX || is_android || is_ios || imgcol<700){
        this.divwidth=imgcol-21;
        this.infocol=1;
      }else{
        this.divwidth=700-21;
        this.infocol=2;
      }
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
    },
    getPermitList(){
      var that = this
      let data = {
        lx: "getusepermitlist",
        procext:"yhjj"
      };
      that.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.Prmitlist=res["records"];
            this.Gaslist=res["recordc"];
          }
        },
        (failure) => {}
      );
    },
  },
  created() {
    let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    if (logininfo) {
      this.LoginInfo=logininfo;
    }
    this.getPermitList();
  },
  mounted() {
    window.onresize = () => {
      this.initDivSize();
    };
    this.initDivSize();
  },
}
</script>
<style scoped>

.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-input-group__append, .el-input-group__prepend {
    background: #5593f0 !important;
}
.el-form-item {
    margin-bottom: 0px;
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:20%;
  max-width:150px;
  min-width:80px;
}
.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  width: 100px;
  margin-left: 20px;
}
.el-card__body{
  padding:0  !important; 
}
.drawer-prmit{
  /* width:70%;
  max-width:700px;
  min-width:300px; */
  background-color:rgb(240, 240, 240)
}
  </style>

